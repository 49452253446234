$(function() {
	// setTimeout(function(){
	// 	$('.start p').fadeIn(1200);
	// },1000); //1秒後にロゴをフェードイン!
	setTimeout(function(){
		$('.start').fadeOut(800);
		$('.divFix').fadeOut(1500);
	},4500); //4.5秒後にロゴ含め真っ白背景をフェードアウト！
  setTimeout(function(){
    $('.topPageMain').fadeIn(1200);
  },4600);//4.6秒後にロmainが表示。
});
